<template>
  <div id="parenter" class="h-auto my-auto border p-6 rounded-xl">
    <div
      class="navbar mb-2 shadow-lg bg-neutral text-neutral-content rounded-box"
      id="navbar"
    >
      <div class="px-2 mx-2">
        <span class="text-lg font-bold">
          Hi! sorry for the inconvenience, we are building a new website
          <br />
          for now, fell free to contact us via the form
        </span>
      </div>
    </div>

    <div class="form-control">
      <label class="label">
        <span class="label-text">Name</span>
      </label>
      <input
        placeholder="Name"
        class="input input-info input-bordered"
        :class="[
          nameFlag
            ? 'input input-warning input-bordered'
            : 'input input-info input-bordered',
        ]"
        type="text"
        v-model="name"
      />
      <label class="label">
        <span class="label-text-alt" v-if="nameFlag">Please enter name</span>
      </label>

      <label class="label">
        <span class="label-text">Phone Number</span>
      </label>
      <input
        placeholder="Phone Number"
        type="tel"
        v-model="phoneNumber"
        pattern="[+]?[0-9]{9,12}"
        class="input input-info input-bordered"
        :class="[
          flag
            ? 'input input-info input-bordered'
            : 'input input-warning input-bordered',
        ]"
      />
      <label class="label">
        <span class="label-text-alt" v-if="Invalid"
          >Please enter valid phone Number</span
        >
      </label>

      <label class="label">
        <span class="label-text">Message</span>
      </label>
      <textarea
        class="textarea h-24 textarea-bordered textarea-info"
        :class="[
          messageFlag
            ? 'textarea h-24 textarea-bordered textarea-warning'
            : 'textarea h-24 textarea-bordered textarea-info',
        ]"
        placeholder="Message"
        v-model="message"
      ></textarea>
      <label class="label">
        <span class="label-text-alt" v-if="messageFlag"
          >Please enter message</span
        >
      </label>
      <button
        data-sitekey="6LcIHzQeAAAAAIBTSh7QM9WLb18H7WAIfq3n4Pe0"
        class="btn modal-button g-recaptcha"
        :class="[
          buttonFlag ? 'btn modal-button g-recaptcha' : '  btn btn-lg loading',
        ]"
        v-on:click="
          {
            check();
          }
        "
      >
        Send
      </button>
    </div>
    <Alert :dialogueFlag="dialogueFlag" />
  </div>
</template>

<script>
import Alert from "./Alert.vue";
export default {
  name: "HelloWorld",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Alert,
  },
  props: {
    msg: String,
  },
  data: function () {
    return {
      name: "",
      phoneNumber: "",
      message: "",
      flag: true,
      Invalid: false,
      nameFlag: false,
      messageFlag: false,
      innerFlag: true,
      buttonFlag: true,
      dialogueFlag: false,
      reloadFlag: true,
    };
  },
  watch: {
    name(value) {
      if (value !== "") {
        this.nameFlag = false;
      } else {
        this.nameFlag = true;
      }
    },
    message(value) {
      if (value !== "") {
        this.messageFlag = false;
      } else {
        this.messageFlag = true;
      }
    },
    phoneNumber(value) {
      if (value === "") {
        this.Invalid = true;
        this.flag = false;
      } else {
        this.phoneNumber = value;
        this.Invalid = false;
        this.flag = true;
      }
    },
  },
  methods: {
    forceUpdate() {
      window.location.reload();
      console.log("Force update");
    },
    // eslint-disable-next-line no-unused-vars
    async check() {
      if (this.name === "") {
        this.nameFlag = true;
        this.innerFlag = false;
      }
      if (this.message === "") {
        this.messageFlag = true;
        this.innerFlag = false;
      }
      if (this.phoneNumber === "") {
        this.Invalid = true;
        this.flag = false;
        this.innerFlag = false;
      }
      if (this.innerFlag === true) {
        this.buttonFlag = false;
        const requestOptions = {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: this.name,
            phonenumber: this.phoneNumber,
            msg: this.message,
          }),
        };
        let response = await fetch(
          "https://auto.toxiclabs.net/webhook/test",
          requestOptions
        );
        console.log("sending post", response);
        if (response.status === 200) {
          // eslint-disable-next-line no-unused-vars
          await new Promise(function (resolve, reject) {
            // Setting 2000 ms time
            setTimeout(resolve, 2000);
          });
          this.buttonFlag = true;
          this.dialogueFlag = true;
          this.forceUpdate();
        }
      }
      console.log("check");
      this.innerFlag = true;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar {
  background-color: #6246ea;
}
button {
  margin-top: 20px;
  background-color: #6246ea;
}
</style>
